import { chunk } from 'lodash';
import { identity, pickBy } from 'lodash';
import EditorData from 'components/editor-data/EditorData';
import DataHelpers from 'components/data/DataHelpers';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';

export default class CreativeBuilderDCM {
    static structureDone = {};
    static adDone = {};

    /**
     * Publish to DCM
     * Published display ads to DCM
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} prefix
     * @param {*} feedData
     */
    static async publishDCMDisplay(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData) {
        // Create structure
        const customerHelper = new CustomerHelperLoader.helper();
        let structure;

        // We have a custom customer helper which is specific for the campaign format.
        if (task.customerHelperFunction && customerHelper[task.customerHelperFunction]) {
            structure = customerHelper[task.customerHelperFunction](uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData);
        }
        // Async customer helper
        else if (task.customerHelperFunctionAsync && customerHelper[task.customerHelperFunctionAsync]) {
            structure = await customerHelper[task.customerHelperFunctionAsync](uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData);
        }
        // We have a customer helper that creates a DCM structure for this client
        else {
            if (customerHelper.publishDCMDisplayStructureAsync) {
                structure = await customerHelper.publishDCMDisplayStructureAsync(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData);
            } else {
                structure = customerHelper.publishDCMDisplayStructure(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData);
            }
        }

        // Parse previously created structure and publish to DCM
        let newTasks = [];
        let newTask;

        const newTasksLists = {
            placements: [],
            placementGroups: [],
            assets: [],
            creatives: [],
            ads: [],
            other: [],
            nochunk: []
        };

        structure.forEach((item) => {
            // Campaign item
            if (item.type === 'campaign') {
                if (!CreativeBuilderDCM.structureDone[item.pointer]) {
                    CreativeBuilderDCM.structureDone[item.pointer] = 1;

                    // Create landingpage
                    newTask = {
                        service: 'doubleclick',
                        type: 'landingPage',
                        structure: {
                            pointer: item.pointer + '-landingpage',
                            campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                            name: 'Link',
                            advertiserId: item.advertiserId,
                            url: item.url
                        }
                    };
                    newTasks.push(newTask);

                    // Create campaign
                    newTask = {
                        service: 'doubleclick',
                        type: 'campaign',
                        structure: {
                            pointer: item.pointer,
                            campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                            name: item.name,
                            defaultLandingPointer: item.pointer + '-landingpage',
                            advertiserId: item.advertiserId,
                            startDate: item.startDate.substring(0, 10),
                            endDate: item.endDate.substring(0, 10)
                        },
                        resources: {
                            url: item.pointer + '-campaign'
                        }
                    };
                    newTasks.push(newTask);

                    // Product for output
                    newTask = {
                        service: 'publish',
                        type: 'product',
                        name: 'Open campaign',
                        product: '{{' + item.pointer + '-campaign}}'
                    };
                    newTasks.push(newTask);
                }
            }
            // Placementgroup
            else if (item.type === 'placementGroup') {
                if (!CreativeBuilderDCM.structureDone[item.pointer]) {
                    CreativeBuilderDCM.structureDone[item.pointer] = 1;

                    newTask = {
                        service: 'doubleclick',
                        type: 'placementGroup',
                        structure: {
                            pointer: item.pointer,
                            name: item.name,
                            advertiserId: item.advertiserId,
                            siteId: item.siteId,
                            campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                            campaignPointer: item.campaignPointer,
                            pricingSchedule: item.pricingSchedule
                        }
                    };
                    newTasksLists.placementGroups.push(newTask);
                }
            }
            // Placement
            else if (item.type === 'placement') {
                if (!CreativeBuilderDCM.structureDone[item.pointer]) {
                    CreativeBuilderDCM.structureDone[item.pointer] = 1;

                    newTask = {
                        service: 'doubleclick',
                        type: 'placement',
                        structure: {
                            pointer: item.pointer,
                            campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                            campaignPointer: item.campaignPointer,
                            campaignId: item.campaignId,
                            placementGroupPointer: item.placementGroupPointer,
                            name: item.name,
                            advertiserId: item.advertiserId,
                            siteId: item.siteId,
                            tagSetting: item.tagSetting,
                            type: 'DISPLAY',
                            sizes: [
                                {
                                    width: item.width,
                                    height: item.height,
                                    defaultAsset: item.defaultAsset ? item.defaultAsset : '{{' + item.defaultAssetIdentifier + '-image}}',
                                    url: item.url
                                }
                            ],
                            pricingSchedule: {
                                startDate: item.startDate.substring(0, 10),
                                endDate: item.endDate.substring(0, 10),
                                pricingType: 'PRICING_TYPE_CPM'
                            }
                        }
                    };

                    if (item.isTracker) {
                        newTask.structure.sizes[0].url = undefined;
                        newTask.structure.sizes[0].defaultAsset = undefined;
                    }

                    newTasksLists.placements.push(newTask);
                }
            }
            // Add tracker
            else if (item.type === 'tracker') {
                // Ad
                const adPointer = item.adPointer ? item.adPointer : item.pointer;
                if (!CreativeBuilderDCM.adDone[adPointer]) {
                    CreativeBuilderDCM.adDone[adPointer] = [item.pointer + '-creative'];
                } else {
                    CreativeBuilderDCM.adDone[adPointer].push(item.pointer + '-creative');
                }

                // Creative
                newTask = {
                    service: 'doubleclick',
                    type: 'creative',
                    structure: {
                        pointer: item.pointer + '-creative',
                        campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                        campaignPointer: item.campaignPointer,
                        campaignId: item.campaignId,
                        advertiserId: item.advertiserId,
                        name: item.creativeName ? item.creativeName : item.name,
                        active: true,
                        type: 'TRACKING_TEXT',
                        size: {
                            width: 1,
                            height: 1
                        },
                        clickThroughUrl: item.url,
                        clickTags: item.clickTags
                    }
                };
                newTasksLists.creatives.push(newTask);

                // Tracker ad
                newTask = {
                    service: 'doubleclick',
                    type: 'ad',
                    structure: {
                        pointer: adPointer,
                        campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                        campaignPointer: item.campaignPointer,
                        campaignId: item.campaignId,
                        placementPointer: item.placementPointer,
                        placementId: item.placementId,
                        adType: 'AD_SERVING_TRACKING',
                        customClickthrough: item.url,
                        active: true,
                        name: item.adName ? item.adName : item.name,
                        deliverySchedule: {
                            impressionRatio: 1,
                            priority: item.priority ? item.priority : 'AD_PRIORITY_01',
                            hardCutoff: item.hardCutoff ? item.hardCutoff : false
                        },
                        startDate: item.startDate.substring(0, 10),
                        endDate: item.endDate.substring(0, 10),
                        creatives: DataHelpers.clone(CreativeBuilderDCM.adDone[adPointer]),
                        geoTargeting: item.geoTargeting
                    }
                };
                newTasksLists.ads.push(newTask);
            }
            // Ad itself
            else if (item.type === 'displayAd') {
                // Image ads
                if (item.displayType && item.displayType === 'IMAGE') {
                    // Asset upload
                    newTask = {
                        service: 'doubleclick',
                        type: 'asset',
                        structure: {
                            pointer: item.pointer + '-asset',
                            campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                            contentUrl: item.assetUrlImage ? item.assetUrlImage : '{{' + item.assetIdentifier + '-image}}',
                            advertiserId: item.advertiserId,
                            width: item.width,
                            height: item.height
                        }
                    };
                    newTasksLists.assets.push(newTask);

                    // Creative
                    newTask = {
                        service: 'doubleclick',
                        type: 'creative',
                        structure: {
                            pointer: item.pointer + '-creative',
                            campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                            campaignPointer: item.campaignPointer,
                            campaignId: item.campaignId,
                            advertiserId: item.advertiserId,
                            name: item.creativeName ? item.creativeName : item.name,
                            active: true,
                            type: 'DISPLAY',
                            size: {
                                width: item.width,
                                height: item.height
                            },
                            clickThroughUrl: item.url,
                            assetPointer: item.pointer + '-asset',
                            clickTags: item.clickTags
                        }
                    };
                    newTasksLists.creatives.push(newTask);
                }
                // Other ads
                else {
                    const assetPointer = item.pointer + '-asset';
                    const assetBackupPointer = item.pointer + '-asset-backup';

                    // Asset upload
                    newTask = {
                        service: 'doubleclick',
                        type: 'asset',
                        structure: {
                            pointer: assetPointer,
                            campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                            contentUrl: item.assetUrlHTML ? item.assetUrlHTML : '{{' + item.assetIdentifier + '-html}}',
                            advertiserId: item.advertiserId,
                            width: item.width,
                            height: item.height
                        }
                    };
                    newTasksLists.assets.push(newTask);

                    // Asset upload
                    newTask = {
                        service: 'doubleclick',
                        type: 'asset',
                        structure: {
                            pointer: assetBackupPointer,
                            campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                            contentUrl: item.assetUrlImage ? item.assetUrlImage : '{{' + item.assetIdentifier + '-image}}',
                            advertiserId: item.advertiserId,
                            width: item.width,
                            height: item.height
                        }
                    };
                    newTasksLists.assets.push(newTask);

                    // Creative
                    newTask = {
                        service: 'doubleclick',
                        type: 'creative',
                        structure: {
                            pointer: item.creativePointer ? item.creativePointer : item.pointer + '-creative',
                            campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                            campaignPointer: item.campaignPointer,
                            campaignId: item.campaignId,
                            advertiserId: item.advertiserId,
                            name: item.creativeName ? item.creativeName : item.name,
                            active: true,
                            type: 'DISPLAY',
                            size: {
                                width: item.width,
                                height: item.height
                            },
                            clickThroughUrl: item.url,
                            assetPointer: assetPointer,
                            backupImage: assetBackupPointer,
                            clickTags: item.clickTags
                        }
                    };
                    newTasksLists.creatives.push(newTask);
                }

                // Ad
                const adPointer = item.adPointer ? item.adPointer : item.pointer;
                if (!CreativeBuilderDCM.adDone[adPointer]) {
                    CreativeBuilderDCM.adDone[adPointer] = [item.pointer + '-creative'];
                } else {
                    CreativeBuilderDCM.adDone[adPointer].push(item.pointer + '-creative');
                }

                newTask = {
                    service: 'doubleclick',
                    type: 'ad',
                    structure: {
                        pointer: adPointer,
                        campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                        campaignPointer: item.campaignPointer,
                        campaignId: item.campaignId,
                        placementPointer: item.placementPointer,
                        placementId: item.placementId,
                        adType: 'AD_SERVING_STANDARD_AD',
                        active: item.placementPointer || item.placementId ? true : false,
                        name: item.adName ? item.adName : item.name,
                        deliverySchedule: {
                            impressionRatio: 1,
                            priority: item.priority ? item.priority : 'AD_PRIORITY_01',
                            hardCutoff: item.hardCutoff ? item.hardCutoff : false
                        },
                        startDate: item.startDate.substring(0, 10),
                        endDate: item.endDate.substring(0, 10),
                        creatives: DataHelpers.clone(CreativeBuilderDCM.adDone[adPointer]),
                        geoTargeting: item.geoTargeting
                    }
                };
                newTasksLists.ads.push(newTask);

                if (!item.disableAutomaticActivate) {
                    newTask = {
                        service: 'doubleclick',
                        type: 'setDefaultActive',
                        structure: {
                            pointer: adPointer,
                            campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                            campaignPointer: item.campaignPointer,
                            campaignId: item.campaignId
                        }
                    };
                    newTasksLists.other.push(newTask);
                }
            }
            // Handle the placement assignments
            else if (item.type === 'ad') {
                newTask = {
                    service: 'doubleclick',
                    type: 'ad',
                    structure: {
                        pointer: item.pointer,
                        campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                        campaignPointer: item.campaignPointer,
                        campaignId: item.campaignId,
                        placementPointer: item.placementPointer,
                        placementId: item.placementId,
                        adType: 'AD_SERVING_STANDARD_AD',
                        active: item.placementPointer || item.placementId ? true : false,
                        name: item.name,
                        deliverySchedule: {
                            impressionRatio: 1,
                            priority: item.priority ? item.priority : 'AD_PRIORITY_01',
                            hardCutoff: item.hardCutoff ? item.hardCutoff : false
                        },
                        startDate: item.startDate.substring(0, 10),
                        endDate: item.endDate.substring(0, 10),
                        creatives: [item.creativePointer],
                        geoTargeting: item.geoTargeting,
                        customClickthrough: item.customClickthrough,
                        urlSuffixes: item.urlSuffixes
                    }
                };
                newTasksLists.ads.push(newTask);
            }
            // Handle the placement assignments
            else if (item.type === 'adPlacementAssignment') {
                const adPointer = item.adPointer ? item.adPointer : item.pointer;
                newTask = {
                    service: 'doubleclick',
                    type: 'adPlacementAssignment',
                    structure: {
                        campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                        campaignPointer: item.campaignPointer,
                        placementPointers: item.placementPointers,
                        pointer: adPointer,
                        activateAd: item.activateAd ? true : false
                    }
                };
                newTasksLists.other.push(newTask);
            }
            // Handle the creative assignments
            else if (item.type === 'adCreativeAssignment') {
                const adPointer = item.adPointer ? item.adPointer : item.pointer;
                newTask = {
                    service: 'doubleclick',
                    type: 'adCreativeAssignment',
                    structure: {
                        adId: item.adId,
                        campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                        creativePointer: item.creativePointer,
                        pointer: adPointer
                    }
                };
                newTasksLists.nochunk.push(newTask);
            }
            // Asset only
            else if (item.type === 'asset') {
                // Asset upload
                newTask = {
                    service: 'doubleclick',
                    type: 'asset',
                    structure: {
                        pointer: item.pointer + '-asset',
                        campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                        contentUrl: item.assetUrlHTML ? item.assetUrlHTML : '{{' + item.assetIdentifier + '-html}}',
                        advertiserId: item.advertiserId,
                        width: item.width,
                        height: item.height
                    }
                };
                pickBy(newTask.structure, identity);
                newTasksLists.assets.push(newTask);
            }
            // Creative only
            else if (item.type === 'creative') {
                let assetPointer = item.pointer + '-asset';
                let assetBackupPointer = item.pointer + '-asset-backup';

                if (item.assetPointer) {
                    assetPointer = item.assetPointer;
                }
                if (item.assetBackupPointer) {
                    assetBackupPointer = item.assetBackupPointer;
                }

                // Asset upload
                newTask = {
                    service: 'doubleclick',
                    type: 'asset',
                    structure: {
                        pointer: assetPointer,
                        campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                        contentUrl: item.assetUrlHTML ? item.assetUrlHTML : '{{' + item.assetIdentifier + '-html}}',
                        advertiserId: item.advertiserId,
                        width: item.width,
                        height: item.height
                    }
                };
                pickBy(newTask.structure, identity);
                newTasksLists.assets.push(newTask);

                // Asset upload
                newTask = {
                    service: 'doubleclick',
                    type: 'asset',
                    structure: {
                        pointer: assetBackupPointer,
                        campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                        contentUrl: item.assetUrlImage ? item.assetUrlImage : '{{' + item.assetIdentifier + '-image}}',
                        advertiserId: item.advertiserId,
                        width: item.width,
                        height: item.height
                    }
                };
                pickBy(newTask.structure, identity);
                newTasksLists.assets.push(newTask);

                newTask = {
                    service: 'doubleclick',
                    type: 'creative',
                    structure: {
                        id: item.id,
                        pointer: item.pointer + '-creative',
                        campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                        campaignId: item.campaignId,
                        campaignPointer: item.campaignPointer,
                        advertiserId: item.advertiserId,
                        name: item.creativeName ? item.creativeName : item.name,
                        type: 'DISPLAY',
                        size: {
                            width: item.width,
                            height: item.height
                        },
                        clickThroughUrl: item.url,
                        assetPointer: assetPointer,
                        backupImage: assetBackupPointer,
                        clickTags: item.clickTags,
                        active: item.inActive ? false : true
                    }
                };
                pickBy(newTask.structure, identity);
                newTasksLists.creatives.push(newTask);
            }
        });

        // Loop through the different fields and group into parallel tasks
        const objectOrder = ['placement', 'placementGroups', 'placements', 'assets', 'creatives', 'ads', 'other', 'nochunk'];
        let i;

        for (i in objectOrder) {
            const key = objectOrder[i];

            // Assets take longer, so keep at 8
            let chunks;
            if (key === 'assets') {
                chunks = chunk(newTasksLists[key], 8);
            }
            // We do not want chunks
            else if (key === 'nochunk') {
                chunks = chunk(newTasksLists[key], 1);
            }
            // The other ones, 4 at the time to prevent api quota
            else {
                chunks = chunk(newTasksLists[key], 4);
            }

            for (const j in chunks) {
                newTasks = [
                    ...newTasks,
                    {
                        service: 'publish',
                        type: 'parallel',
                        tasks: chunks[j]
                    }
                ];
            }
        }

        return newTasks;
    }

    /**
     * Publish to DCM
     * Published static video to DCM
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} prefix
     * @param {*} feedData
     */
    static async publishDCMStaticVideo(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData) {
        // Create structure
        const customerHelper = new CustomerHelperLoader.helper();
        const structure = customerHelper.publishDCMStaticVideoStructure(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix);

        // Parse previously created structure and publish to DCM

        let newTasks = [];
        let newTask;

        structure.forEach((item) => {
            // Campaign item
            if (item.type === 'campaign') {
                if (!CreativeBuilderDCM.structureDone[item.pointer]) {
                    CreativeBuilderDCM.structureDone[item.pointer] = 1;

                    // Create landingpage
                    newTask = {
                        service: 'doubleclick',
                        type: 'landingPage',
                        structure: {
                            pointer: item.pointer + '-landingpage',
                            campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                            name: 'Link',
                            advertiserId: item.advertiserId,
                            url: item.url
                        }
                    };
                    newTasks.push(newTask);

                    // Create campaign
                    newTask = {
                        service: 'doubleclick',
                        type: 'campaign',
                        structure: {
                            pointer: item.pointer,
                            campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                            name: item.name,
                            defaultLandingPointer: item.pointer + '-landingpage',
                            advertiserId: item.advertiserId,
                            startDate: item.startDate.substring(0, 10),
                            endDate: item.endDate.substring(0, 10)
                        },
                        resources: {
                            url: item.pointer + '-campaign'
                        }
                    };
                    newTasks.push(newTask);

                    // Product for output
                    newTask = {
                        service: 'publish',
                        type: 'product',
                        name: 'Open campaign',
                        product: '{{' + item.pointer + '-campaign}}'
                    };
                    newTasks.push(newTask);
                }
            }

            // Placement
            else if (item.type === 'placement') {
                if (!CreativeBuilderDCM.structureDone[item.pointer]) {
                    CreativeBuilderDCM.structureDone[item.pointer] = 1;

                    newTask = {
                        service: 'doubleclick',
                        type: 'placement',
                        structure: {
                            pointer: item.pointer,
                            campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                            campaignPointer: item.campaignPointer,
                            name: item.name,
                            advertiserId: item.advertiserId,
                            siteId: item.siteId,
                            type: 'IN_STREAM_VIDEO',
                            sizes: [
                                {
                                    width: item.width,
                                    height: item.height
                                }
                            ],
                            pricingSchedule: {
                                startDate: item.startDate.substring(0, 10),
                                endDate: item.endDate.substring(0, 10),
                                pricingType: 'PRICING_TYPE_CPM'
                            }
                        }
                    };
                    newTasks.push(newTask);
                }
            }

            // Ad itself
            else if (item.type == 'video') {
                // Asset upload
                newTask = {
                    service: 'doubleclick',
                    type: 'asset',
                    structure: {
                        pointer: item.pointer + '-asset',
                        campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                        contentUrl: item.contentUrl,
                        advertiserId: item.advertiserId,
                        width: item.width,
                        height: item.height
                    }
                };
                newTasks.push(newTask);

                // Creative
                newTask = {
                    service: 'doubleclick',
                    type: 'creative',
                    structure: {
                        pointer: item.pointer + '-creative',
                        campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                        campaignPointer: item.campaignPointer,
                        advertiserId: item.advertiserId,
                        name: item.creativeName ? item.creativeName : item.name,
                        active: false,
                        type: 'INSTREAM_VIDEO',
                        size: {
                            width: item.width,
                            height: item.height
                        },
                        clickThroughUrl: item.url,
                        assetPointer: item.pointer + '-asset'
                    }
                };
                newTasks.push(newTask);

                // Ad
                const adPointer = item.adPointer ? item.adPointer : item.pointer;
                if (!CreativeBuilderDCM.adDoneCreatives[adPointer]) {
                    CreativeBuilderDCM.adDoneCreatives[adPointer] = [item.pointer + '-creative'];
                } else {
                    CreativeBuilderDCM.adDoneCreatives[adPointer].push(item.pointer + '-creative');
                }

                // Filter previously ad with same setup
                newTasks = newTasks.filter((taskItem) => taskItem.type != 'ad' || !taskItem.structure || taskItem.structure.pointer != adPointer);

                // Create the ad
                newTask = {
                    service: 'doubleclick',
                    type: 'ad',
                    structure: {
                        pointer: adPointer,
                        campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId(),
                        campaignPointer: item.campaignPointer,
                        placementPointer: item.placementPointer,
                        adType: 'AD_SERVING_STANDARD_AD',
                        active: false,
                        name: item.adName ? item.adName : item.name,
                        deliverySchedule: {
                            impressionRatio: 1,
                            priority: 'AD_PRIORITY_01'
                        },
                        startDate: item.startDate.substring(0, 10),
                        endDate: item.endDate.substring(0, 10),
                        creatives: DataHelpers.clone(CreativeBuilderDCM.adDoneCreatives[adPointer])
                    }
                };
                newTasks.push(newTask);

                // Set all items to active
                newTasks = newTasks.filter((taskItem) => taskItem.type !== 'setAllActive' || !taskItem.structure || taskItem.structure.pointer != adPointer);

                newTask = {
                    service: 'doubleclick',
                    type: 'setAllActive',
                    structure: {
                        includeConversionSleep: true,
                        campaignPointer: item.campaignPointer,
                        pointer: adPointer,
                        activateCreatives: true,
                        campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId()
                    }
                };
                newTasks.push(newTask);
            }
        });

        return newTasks;
    }
}
