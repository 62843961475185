import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import EditorData from 'components/editor-data/EditorData';

/**
 * CreativeBuilderPublishDV360
 * Publish to DV360
 */
export default class CreativeBuilderPublishDV360 {
    static structureDone = {};

    /**
     * handleItem
     * Publish an item to the DV360 channel
     * @param {*} uuid
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} feedData
     */
    static async handleItem(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData) {
        const channelSetup = data.channelSetup;
        const channelData = data.data[subsetItem].channelData;
        const assetData = data.data[subsetItem].assetData;
        return await this.handleDV360(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, assetData, feedData);
    }

    /**
     * Handle DV360 publish
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} assetData
     * @param {*} feedData
     */
    static async handleDV360(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, assetData, feedData) {
        const newTasks = [];
        let structure = [];
        const customerHelper = new CustomerHelperLoader.helper();

        // Predefined structure for this customer
        if (customerHelper.publishDV360Structure) {
            try {
                structure = customerHelper.publishDV360Structure(
                    uuid,
                    setNr,
                    task,
                    data,
                    language,
                    subsetItem,
                    subsetItemNr,
                    channelSetup,
                    channelData,
                    assetData
                );
            } catch (e) {
                console.log('Structure error', e);
            }
        }
        // Set default campaign structure
        else {
            structure = CreativeBuilderPublishDV360.generateDefaultStructure(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                assetData
            );
        }

        // Create structure
        structure.forEach((item) => {
            // Validate whether we didn't already published it in this job
            if (item.pointer && !CreativeBuilderPublishDV360.structureDone[item.pointer]) {
                CreativeBuilderPublishDV360.structureDone[item.pointer] = true;
                newTasks.push({
                    ...item,
                    service: !item.service ? 'dv360' : item.service,
                    campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId()
                });
            }
        });

        return newTasks;
    }

    /**
     * Generate default structure
     */
    static generateDefaultStructure() {
        return [];
    }
}
